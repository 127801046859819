import { Button } from "../../../Common/AbstractComponents/Button";
import { ErrorPopup } from "../../RailwayPage/Components/ErrorPopup";

export class AuthButton {
    constructor({ authModal }) {
        this._authModal = authModal;
        
        this._init();
    }

    _init() {
        if ($('#header_auth_btn').length === 0) return false;
        
        // this._authBtn = new Button({
        //     button: $('#header_auth_btn, [data-login]'),
        //     onClick: this._showAuthModal.bind(this),
        // });

        $(document).on('click', '#header_auth_btn, [data-login]', this._showAuthModal.bind(this));
    }

    _showAuthModal() {
        ErrorPopup.hide();
        this._authModal.showModal();
    }

    destroy() {
        // this._authBtn.destroy();
        $(document).off('click', '#header_auth_btn, [data-login]');
        $('#header_auth_btn').remove();
    }

}