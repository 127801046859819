import { Button } from "../../../Common/AbstractComponents/Button";
import AjaxBuilder from "../../../Common/AjaxBuilder";
import { Util } from "../../../Common/Util";
import { validateField, validateFields } from "../../../Common/Validator/Validator";
import { Loader } from "../../BusPage/Loader";
import eventBus from '../../../Common/EventBus';

export class Login {
    constructor({ destroyAuthComponents }) {
        this._destroyAuthComponents = destroyAuthComponents;
        this._init();

        this._loader = new Loader();
    }

    _init() {
        this.$loginForm = $('.account_popup_tab[data-tab="login"]');

        if (this.$loginForm.length === 0) return false;
        
        this._submitBtn = new Button({
            button: $('#login_customer'),
            onClick: this._submitForm.bind(this),
        });

        this.$loginForm.on('input', 'input._input-validate', this._dataValidateHandler.bind(this));
        validateFields(this.$loginForm.find('#login_form'));
    }

    showForm() {
        this.$loginForm.addClass('_active');
        validateFields(this.$loginForm);
    }

    hideForm() {
        this.$loginForm.removeClass('_active');
    }

    _submitForm() {
        this._loader.show();
        new AjaxBuilder({
            route: `${Util.getLocalization()}frontend/login/`,
            data: {
                email: this.$loginForm.find('input[name="email"]').val(),
                password: this.$loginForm.find('input[name="password"]').val(),
                remember: this.$loginForm.find('input[name="remember"]').prop('checked') ? 1 : 0,
            },
            token: Util.token(),
        }, 'POST').execute({
            success: this._submitFormSuccess.bind(this),
            error: this._submitFormError.bind(this),
        })
    }

    _submitFormSuccess(data) {
        eventBus.publish('checkout-auth', data.response.user);

        this._destroyAuthComponents();
        Util.setToken(data.response.new_token);
        $('.header__content .header__leng').after(data.response.account_btn);
        $('body').removeClass('no_scroll');
        this._loader.hide();
    }

    _submitFormError(data) {
        const errors = data.responseJSON.errors;
        $('._error_field').html('');
        for (let key in errors) {
            const input = this.$loginForm.find(`input[name="${key}"]`);

            $(input).closest('.auth_input').addClass('_not-valid');
            $(input).after(`
                <div class="_error_field">${errors[key][0]}</div>
            `);
        }
        this._loader.hide();
    }

    _dataValidateHandler(event) {
        validateField(event.target);
    }

    destroy() {
        this._submitBtn.destroy();
        this.$loginForm.off();

        return true;
    }
}