$(() => {
    const $cookieBanner = $('.cookie');
    const cookieName = 'cookie_accepted';

    // Проверяем, установлены ли куки
    if (!document.cookie.includes(`${cookieName}=true`)) {
        // Если куки нет, показываем баннер
        $cookieBanner.removeClass('hide');
    }

    // Обрабатываем клик на кнопку "Принять"
    $('.cookie__btn').on('click', function () {
        // Скрываем баннер
        $cookieBanner.addClass('hide');

        // Устанавливаем куки на 1 год
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1); // Устанавливаем срок действия куки на 1 год
        document.cookie = `${cookieName}=true; expires=${date.toUTCString()}; path=/`;
    });
});