export class ErrorPopup {
    static show({ title = null, message = null }) {
        if (title) {
            $('#railway_popup_error').find('.bus_popup_title div').text(message);
        }

        if (message) {
            $('#railway_popup_error').find('.bus_popup_content p').html(message);
        }

        $('html').addClass('_open_bus_popup');
        $('.bus_overlay').addClass('_active');
        $('#railway_popup_error').addClass('_active');
    }

    static hide() {
        $('html').removeClass('_open_bus_popup');
        $('.bus_overlay').removeClass('_active');
        $('#railway_popup_error').removeClass('_active');
    }
}