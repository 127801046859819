if (document.querySelector('.burgerToggle')) {
    document.querySelector('.headerMenuBtnWrap')
      .addEventListener( 'click', e => {
        // e.stopPropagation();
        document.querySelector('.burgerToggle')
          .checked = false;
      $('body').toggleClass('no_scroll');
    })
    document.querySelector('.burgerBtn')
      .addEventListener( 'click', e => {
        $('body').toggleClass('no_scroll');
    })
}