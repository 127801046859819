import { Button } from "../../../Common/AbstractComponents/Button";
import { Modal } from "./Modal";

export class AuthModal extends Modal {
    constructor({ loginForm, registrationForm, showResetModal }) {
        super();

        this._loginForm = loginForm;
        this._registrationForm = registrationForm;
        this._showResetModal = showResetModal;

        this._init();
    }

    _init() {
        this.$modal = $('#account_popup');

        if (this.$modal.length === 0) return false;

        $(document).on('click', '.account_popup_nav_item:not(._active)', (e) => {
            let tab = $(e.target).data('tab');

            if (tab === 'register') {
                this._loginForm.hideForm();
                this._registrationForm.showForm();
            } else {
                this._registrationForm.hideForm();
                this._loginForm.showForm();
            }

            $('.account_popup_nav_item._active').removeClass( '_active' );
            $(e.target).addClass('_active');
        });

        this._resetModalBtn = new Button({
            button: this.$modal.find('#reset_modal_btn'),
            onClick: this._showResetModal,
        });

    }

    destroy() {
        this._resetModalBtn.destroy();
        this.$modal.remove();

        return true;
    }
}