import { CustomException } from "../Exceptions/CustomException";
import { getButton, hideErrorMessage, isEmpty, isErrorAlreadyExists, showErrorMessage, shouldButtonBeActivated, showValidIcon } from "./Utilities/utilities";
import { validationRequires } from './Utilities/validationFunctions';

//Список полей в форме, с флагами пройденной валидации
const forms = {};

//Прогонит все поля и проверит нужна ли валидация и не пусты ли они
export function validateFields(form) {
    const formId = $(form).attr('id');
    forms[formId] = new Map();

    $(form).find('input._input-validate').each((index, input) => {
        const fieldCredentials = getFieldCredentials(input);
        _checkField(fieldCredentials);

        const submitButton = getButton(fieldCredentials.formId);
        const activateButton = shouldButtonBeActivated(forms[formId], submitButton);

        submitButton.prop('disabled', activateButton);
    });
}

//Валидация поля
export function validateField(target) {
    const fieldCredentials = getFieldCredentials(target);
    hideErrorMessage(target);
    const validateInput = _checkField(fieldCredentials);

    if (isEmpty(validateInput)) {
        hideErrorMessage(target);
        showValidIcon(target);
    } else {
        showErrorMessage(target, validateInput);
    }

    const submitButton = getButton(fieldCredentials.formId);
    const activateButton = shouldButtonBeActivated(forms[fieldCredentials.formId], submitButton);
    submitButton.prop('disabled', activateButton);

    return validateInput;
}

function _checkField(fieldCredentials) {
    const validateInput = validate(fieldCredentials);

    if (isEmpty(validateInput)) {
        forms[fieldCredentials.formId].set(fieldCredentials.id, true);
    } else {
        forms[fieldCredentials.formId].set(fieldCredentials.id, false);
    }

    return validateInput;
}

function getFieldCredentials(target) {
    if (!target) {
        throw new CustomException('No field was provided!');
    }

    const type = $(target).data('type');
    const required = $(target).attr('required');
    
    let compareValue = null;
    if (type === 'password_confirmation') {
        compareValue = $(target).closest('form').find('input[name="password"]').val();
    } else if(type === 'password') {
        compareValue = $(target).closest('form').find('input[name="password_confirmation"]').val();
    }

    return {
        id: $(target).attr('id'),
        formId: $(target).closest('form').attr('id'),
        type,
        value: type === "checkbox" ? $(target).prop('checked') : $(target).val(),
        required: !!required,
        validations: validationRequires[type],
        compareValue: compareValue,
    }
}

function validate(field) {
    if (!field) {
        throw new CustomException('No field was provided!');
    }
    const error = {};
    const validations = field.validations;

    if (validations) {
        for (const method of validations) {

            const result = method(field.value, field.type, field.compareValue);
            if (result === true) continue;
            error.code = result;
            error.blockId = field.id;
        }
    }

    return error;
}
