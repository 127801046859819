const ratingTabs = document.querySelectorAll(
    ".info-tabs__nav-item"
);
const ratingWraps = document.querySelectorAll(".info-tabs__content-item-box");

if (ratingTabs !== null) {
    class InformationSearch {
        constructor() {
            this._ratingActiveWrapper = null;
            this._itemsWrapper = null;
            this._searchActiveWrapper = null;
            this._searchInput = null;
            this._type = null;

            this._route = $('.info-tabs.inner .info-tabs__nav').data('route');

            this._findActiveWrapper();
            this._initHandler();
        }

        _search(event) {
            const keyCode = event.keyCode ? event.keyCode : event.which;
            if (keyCode === '13') return;

            event.preventDefault();

            let value = this._searchInput.val();

            const data = {
                type: this._type,
                searchValue: value,
            }

            if ( value.length ){
                $('[data-load-more-info][data-type=' + this._type + ']').hide();
            } else{
                $('[data-load-more-info][data-type=' + this._type + ']').show();
            }

            $.ajax({
                url: this._route,
                type: 'GET',
                data,
                success: this._searchSuccess.bind(this),
                error: this._searchError.bind(this),
            });
        }

        _searchSuccess(data) {
            if (!data.view) return;

            this._itemsWrapper.html(data.view);
        }

        _searchError(data) {
            console.log('Error: ', data);
        }

        changeActiveItem() {
            this._removeHandler();
            this._findActiveWrapper();
            this._initHandler();
        }

        _findActiveWrapper() {
            this._ratingActiveWrapper = $('.info-tabs__content-item-box_active');
            this._itemsWrapper = (this._ratingActiveWrapper).find('.info-tabs__content-items');
            this._searchActiveWrapper = $(this._ratingActiveWrapper).find('.info-tabs__input-wrap');
            this._searchInput = $(this._searchActiveWrapper).find('input.info__search');
            this._type = $(this._searchActiveWrapper).find('input.type').val();
        }

        _removeHandler() {
            // $(this._searchActiveWrapper).off('keypress');
            $(this._searchActiveWrapper).off('keyup');
        }

        _initHandler() {
            $(this._searchActiveWrapper).on('keyup', this._search.bind(this));
            // $(this._searchActiveWrapper).on('keypress', this._searchEnter.bind(this));
        }
    }

    const infoSearch = new InformationSearch;

    ratingTabs.forEach((item) => {
        item.addEventListener("click", (e) => {
            const id = Number(e.target.getAttribute("data-id"));
            ratingTabs.forEach((tab) => tab.classList.remove("active"));
            ratingWraps.forEach((wrap) =>
                wrap.classList.remove("info-tabs__content-item-box_active")
            );
            $(`.info-tabs__nav-item[data-id="${id}"]`).addClass('active');
            $(`.info-tabs__content-item-box[data-tab="${id}"]`).addClass('info-tabs__content-item-box_active');

            infoSearch.changeActiveItem();
        });
    });
}


$('[data-load-more-info]').click(function() {
    let type = $(this).attr('data-type');

    $.ajax({
        _token: $('meta[name="csrf-token"]').attr("content"),
        // url: "{{ route('frontend.helpfullInformation.loadMore') }}",
        url: "/helpful-information/load-more",
        data: {type: type},
        beforeSend: function() { 
            $('[data-load-more-info][data-type=' + type + ']').hide();
        },
        success: function(data){
            $('.info-tabs__content-items[data-type=' + type + ']').append(data);
        },
        error: function () {
            
        },
    });

    return false;
});

// todo todo_arm глянуть шо це, походу с главной
if (ratingWraps !== null) {
    const more = document.querySelectorAll(".rating-wrap__list--inner-more");

    if (more !== null) {
        more.forEach(item => {
            item.addEventListener("click", (e) => {
                e.target.style.display = "none";
                const ratingWrapper = e.target.previousElementSibling;
                ratingWrapper.classList.add('rating-wrap__list--inner__wrapper-active');
            });
        });
    }
}

