// eventBus.js
class EventBus {
    constructor() {
        if (!window.eventBus) {
            this.events = {};
            window.eventBus = this;
        }

        return window.eventBus;
    }

    subscribe(event, listener) {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].push(listener);
    }

    publish(event, data) {
        if (this.events[event]) {
            this.events[event].forEach(listener => listener(data));
        }
    }
}

const eventBus = new EventBus();
export default eventBus;