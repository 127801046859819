import { Button } from "../../../Common/AbstractComponents/Button";
import AjaxBuilder from "../../../Common/AjaxBuilder";
import { Util } from "../../../Common/Util";
import { validateField, validateFields } from "../../../Common/Validator/Validator";
import { AlertModal } from "./AlertModal";
import { Modal } from "./Modal";

export class ResetModal extends Modal {
    constructor() {
        super();

        this._init();
    }

    _init() {
        this.$modal = $('#reset_pasword');

        if (this.$modal.length === 0) return false;
        
        this.$form = this.$modal.find('form#reset_form');
        this.$notification = this.$modal.find('.account_popup_info');

        this._submitBtn = new Button({
            button: this.$form.find('#reset_form_btn'),
            onClick: this._submitForm.bind(this),
        });

        this.$form.on('input', 'input._input-validate', this._dataValidateHandler.bind(this));
        validateFields(this.$form);
    }

    _submitForm() {
        this._loader.show();
        new AjaxBuilder({
            route: `${Util.getLocalization()}frontend/password/email/`,
            data: {
                email: this.$form.find('input[name="email"]').val(),
            },
            token: Util.token(),
        }, 'POST').execute({
            success: this._submitFormSuccess.bind(this),
            error: this._submitFormError.bind(this),
        })
    }

    _submitFormSuccess(data) {
        this.$modal.removeClass('_active');
        AlertModal.showModal(data.title, data.message);

        this._loader.hide();
    }

    _submitFormError(data) {
        this._loader.hide();
        const errors = data.responseJSON.errors;
        for (let key in errors) {
            const input = this.$form.find(`input[name="${key}"]`);

            $(input).closest('.auth_input').addClass('_not-valid');
            $(input).after(`
                <div class="_error_field">${errors[key][0]}</div>
            `);
        }
    }

    _dataValidateHandler(event) {
        validateField(event.target);
    }

    destroy() {
        this._submitBtn.destroy();
        this.$form.off();
        this.$modal.remove();

        return true;
    }
}