import { Loader } from "../../BusPage/Loader";

export class Modal {
    constructor() {
        this.$modal = null;
        this.$notification = null;
        this.$authOverlay = $('#account_overlay');

        this._loader = new Loader();
    }

    showModal(event) {
        this.$modal.addClass('_active');
        this.$authOverlay.addClass('_active');
        $('body').addClass('no_scroll');
    }

    hideModal() {
        this.$modal.removeClass('_active');
        this.$authOverlay.removeClass('_active');
        $('body').removeClass('no_scroll');
    }

    showNotification(message, type) {
        this.$notification.text(message);
        this.$notification.addClass(`_active ${type}`);

        setTimeout(() => {
            this.hideNotification();
        }, 4000);
    }

    hideNotification() {
        this.$notification.text();
        this.$notification.removeClass('_active _error _success');
    }
}