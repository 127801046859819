export class Loader {
    show() {
        $('html, body').animate({ scrollTop: 0 }, 1);
        $('html').addClass('_open_bus_popup');
        $('.page').prepend(`
            <div class="reservation_loader">
                <div class="load-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        `);
    }

    hide() {
        $('html').removeClass('_open_bus_popup');
        $('.page').find('.reservation_loader').remove();
    }
}