export const Inputs = {
    NAME_EN : 'name_en',
    SURNAME_EN: 'surname_en',
    FULLNAME_EN: 'fullname_en',
    NAME: 'name',
    SURNAME: 'surname',
    NAME_INTERNATIONALE: 'name_internationale',
    MAIL : 'mail',
    PHONE: 'phone',
    PASSWORD: 'password',
    PASSWORD_CONFIRMATION: 'password_confirmation',
    BORN_DATE: 'born_date',
    BORN_DATE_CHILDREN: 'born_date_children',
    DOCUMENT_SERIES: 'document_series',
    DOCUMENT_NUMBER: 'document_number',
    NUMBER : 'number',
    POSITIVENUMBER : 'positiveNumber',
    START_DATE: 'start_date', // Дата начала
    END_DATE: 'end_date', // Дата окончания
    VIN: 'vin', //ВИн код машины
    YEAR: 'year', //Год выпуска машины
    MIDDLE_NAME: 'middle_name',
    ADDRESS: 'address',
    CODE: 'code', // ИНН
    REG_NUM: 'reg_num', //Номер автомобиля
    STREET: 'street',
    HOUSE: 'house',
    APARTMENT: 'apartment',
    CHARACTER_RANGE: 'character_range',
    CHECKBOX: 'checkbox',
    LEGAL_DETAILS: 'legal_details',
    EGRPOU: 'egrpou',
    AVIA_BORN_DATE_ADDULTS: 'avia_born_date_addults',
    AVIA_BORN_DATE_CHILDREN: 'avia_born_date_children',
    AVIA_BORN_DATE_BABY: 'avia_born_date_baby',
    EXPIRATION_DATE: 'expiration_date',
    INTERNATIONAL_PASSPORT: 'international_passport',
    LATIN_AND_DIGITS: 'latin_and_digits',
};

export const ErrorCodes = {
    NAME_EN_ERROR : 'name-en-error',
    SURNAME_EN_ERROR : 'surname-en-error',
    FULLNAME_EN_ERROR: 'fullname-en-error',
    NAME_ERROR : 'name-error',
    SURNAME_ERROR : 'surname-error',
    NAME_INTERNATIONALE_ERROR: 'name-internationale-error',
    MAIL_ERROR : 'mail-error',
    PHONE_ERROR: 'phone-error',
    PASSWORD_ERROR: 'password-error',
    PASSWORD_CONFIRMATION_ERROR: 'password-confirmation-error',
    BORN_DATE: 'born-date-error',
    DOCUMENT_SERIES: 'document_series-error',
    DOCUMENT_NUMBER: 'document_number-error',
    NAME_MIN_ERROR : 'name-min-error',
    SURNAME_MIN_ERROR : 'surname-min-error',
    EMPTY_FIELD_ERROR : 'empty-field-error',
    NUMBER_ERROR : 'number-error',
    NO_ERROR: 'none',
    AGE_MIN_ERROR: 'age_min_error',
    AGE_MAX_ERROR: 'age_max_error',
    START_DATE_ERROR: 'start-date-error',
    END_DATE_ERROR: 'end-date-error',
    VIN_ERROR: 'vin-error',
    YEAR_ERROR: 'year-error',
    MIDDLE_NAME_ERROR: 'middle-name-error',
    ADDRESS_ERROR: 'address-error',
    CODE_ERROR: 'code-error',
    REG_NUM_ERROR: 'reg-num-error',
    DATE_IS_SAME_ERROR: 'date-is-same-error',
    DATE_IS_BEFORE_ERROR: 'date-is-before-error',
    STREET_ERROR: 'street_error',
    CHARACTER_RANGE_ERROR: 'character-range-error',
    CHECKBOX_ERROR: 'checkbox-error',
    EGRPOU_ERROR: 'egrpou-error',
    AVIA_MIN_ADULT_AGE_ERROR: 'avia_min_adult_age_error',
    AVIA_MAX_ADULT_AGE_ERROR: 'avia_max_adult_age_error',
    AVIA_MIN_CHILDREN_AGE_ERROR: 'avia_min_children_age_error',
    AVIA_MAX_CHILDREN_AGE_ERROR: 'avia_max_children_age_error',
    AVIA_MIN_BABY_AGE_ERROR: 'avia_min_baby_age_error',
    AVIA_MAX_BABY_AGE_ERROR: 'avia_max_baby_age_error',
    EXPIRATION_DATE_ERROR: 'expiration_date_error',
    INTERNATIONAL_PASSPORT_ERROR: 'international_passport_error',
    LATIN_AND_DIGITS_ERROR: 'latin_and_digits_error',
};

export const inputToErrorsMapping = {
    [Inputs.NAME_EN]: {
        DEFAULT_ERROR: ErrorCodes.NAME_EN_ERROR,
        MIN_ERROR: ErrorCodes.NAME_MIN_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.FULLNAME_EN]: {
        DEFAULT_ERROR: ErrorCodes.FULLNAME_EN_ERROR,
        MIN_ERROR: ErrorCodes.NAME_MIN_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.SURNAME_EN]: {
        DEFAULT_ERROR: ErrorCodes.SURNAME_EN_ERROR,
        MIN_ERROR: ErrorCodes.SURNAME_MIN_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.NAME]: {
        DEFAULT_ERROR: ErrorCodes.NAME_ERROR,
        MIN_ERROR: ErrorCodes.NAME_MIN_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.NAME_INTERNATIONALE]: {
        DEFAULT_ERROR: ErrorCodes.NAME_INTERNATIONALE_ERROR,
        MIN_ERROR: ErrorCodes.NAME_MIN_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.SURNAME]: {
        DEFAULT_ERROR: ErrorCodes.SURNAME_ERROR,
        MIN_ERROR: ErrorCodes.SURNAME_MIN_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.MAIL]: {
        DEFAULT_ERROR: ErrorCodes.MAIL_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.PHONE]: {
        DEFAULT_ERROR: ErrorCodes.PHONE_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.PASSWORD]: {
        DEFAULT_ERROR: ErrorCodes.PASSWORD_ERROR,
        CHARACTER_RANGE_ERROR: ErrorCodes.PASSWORD_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.PASSWORD_CONFIRMATION]: {
        COMPARE_ERROR: ErrorCodes.PASSWORD_CONFIRMATION_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.BORN_DATE]: {
        DEFAULT_ERROR: ErrorCodes.BORN_DATE,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
        AGE_MIN_ERROR: ErrorCodes.AGE_MIN_ERROR,
        AGE_MAX_ERROR: ErrorCodes.AGE_MAX_ERROR,
    },
    [Inputs.BORN_DATE_CHILDREN]: {
        DEFAULT_ERROR: ErrorCodes.BORN_DATE,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
        AGE_MIN_ERROR: ErrorCodes.AGE_MIN_ERROR,
        AGE_MAX_ERROR: ErrorCodes.AGE_MAX_ERROR,
    },
    [Inputs.START_DATE]: {
        DEFAULT_ERROR: ErrorCodes.START_DATE_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
        DATE_IS_SAME_ERROR: ErrorCodes.DATE_IS_SAME_ERROR,
        DATE_IS_BEFORE_ERROR: ErrorCodes.DATE_IS_BEFORE_ERROR,
    },
    [Inputs.END_DATE]: {
        DEFAULT_ERROR: ErrorCodes.END_DATE_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
        DATE_IS_SAME_ERROR: ErrorCodes.DATE_IS_SAME_ERROR,
        DATE_IS_BEFORE_ERROR: ErrorCodes.DATE_IS_BEFORE_ERROR,
    },
    [Inputs.DOCUMENT_SERIES]: {
        DEFAULT_ERROR: ErrorCodes.DOCUMENT_SERIES,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.DOCUMENT_NUMBER]: {
        DEFAULT_ERROR: ErrorCodes.DOCUMENT_NUMBER,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.NUMBER]: {
        DEFAULT_ERROR: ErrorCodes.NUMBER_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.POSITIVENUMBER]: {
        DEFAULT_ERROR: ErrorCodes.NO_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.NO_ERROR,
    },
    [Inputs.VIN]: {
        DEFAULT_ERROR: ErrorCodes.VIN_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.YEAR]: {
        DEFAULT_ERROR: ErrorCodes.YEAR_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.MIDDLE_NAME]: {
        DEFAULT_ERROR: ErrorCodes.MIDDLE_NAME_ERROR,
        MIN_ERROR: ErrorCodes.SURNAME_MIN_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.ADDRESS]: {
        DEFAULT_ERROR: ErrorCodes.ADDRESS_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.CODE]: {
        DEFAULT_ERROR: ErrorCodes.CODE_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.EGRPOU]: {
        DEFAULT_ERROR: ErrorCodes.EGRPOU_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.REG_NUM]: {
        DEFAULT_ERROR: ErrorCodes.REG_NUM_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.STREET]: {
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
        CHARACTER_RANGE_ERROR: ErrorCodes.CHARACTER_RANGE_ERROR,
    },
    [Inputs.HOUSE]: {
        DEFAULT_ERROR: ErrorCodes.NUMBER_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
        CHARACTER_RANGE_ERROR: ErrorCodes.CHARACTER_RANGE_ERROR,
    },
    [Inputs.APARTMENT]: {
        DEFAULT_ERROR: ErrorCodes.NUMBER_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.CHARACTER_RANGE]: {
        DEFAULT_ERROR: ErrorCodes.CHARACTER_RANGE_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.CHECKBOX]: {
        DEFAULT_ERROR: ErrorCodes.CHECKBOX_ERROR,
    },
    [Inputs.LEGAL_DETAILS]: {
        DEFAULT_ERROR: ErrorCodes.NAME_INTERNATIONALE_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.AVIA_BORN_DATE_ADDULTS]: {
        DEFAULT_ERROR: ErrorCodes.BORN_DATE,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
        AGE_MIN_ERROR: ErrorCodes.AVIA_MIN_ADULT_AGE_ERROR,
        AGE_MAX_ERROR: ErrorCodes.AVIA_MAX_ADULT_AGE_ERROR,
    },
    [Inputs.AVIA_BORN_DATE_CHILDREN]: {
        DEFAULT_ERROR: ErrorCodes.BORN_DATE,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
        AGE_MIN_ERROR: ErrorCodes.AVIA_MIN_CHILDREN_AGE_ERROR,
        AGE_MAX_ERROR: ErrorCodes.AVIA_MAX_CHILDREN_AGE_ERROR,
    },
    [Inputs.AVIA_BORN_DATE_BABY]: {
        DEFAULT_ERROR: ErrorCodes.BORN_DATE,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
        AGE_MIN_ERROR: ErrorCodes.AVIA_MIN_BABY_AGE_ERROR,
        AGE_MAX_ERROR: ErrorCodes.AVIA_MAX_BABY_AGE_ERROR,
    },
    [Inputs.EXPIRATION_DATE]: {
        DEFAULT_ERROR: ErrorCodes.EXPIRATION_DATE_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.INTERNATIONAL_PASSPORT]: {
        DEFAULT_ERROR: ErrorCodes.INTERNATIONAL_PASSPORT_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
    [Inputs.LATIN_AND_DIGITS]: {
        DEFAULT_ERROR: ErrorCodes.LATIN_AND_DIGITS_ERROR,
        EMPTY_FIELD_ERROR: ErrorCodes.EMPTY_FIELD_ERROR,
    },
};

export const regExpressions = {
    [Inputs.NAME_EN] : /^(?!.*\.\..*)(?!.*:.*)(?!.*<.*)(?!.*;.*)[a-z. !@#$%&'*+-/=?^_'({|})~.]+$/iu,
    [Inputs.SURNAME_EN] : /^(?!.*\.\..*)(?!.*:.*)(?!.*<.*)(?!.*;.*)[a-z. !@#$%&'*+-/=?^_'({|})~.]+$/iu,
    [Inputs.FULLNAME_EN]: /^[A-Za-z]{3,} [A-Za-z]{3,}$/,
    [Inputs.NAME] : /^(?!.*\.\..*)(?!.*:.*)(?!.*<.*)(?!.*;.*)[а-я. !@#$%&'*+-/=?^_'({|})~.]+$/iu,
    [Inputs.SURNAME] : /^(?!.*\.\..*)(?!.*:.*)(?!.*<.*)(?!.*;.*)[а-я. !@#$%&'*+-/=?^_'({|})~.]+$/iu,
    [Inputs.NAME_INTERNATIONALE]: /^[a-zA-Zа-яА-ЯёЁіІїЇєЄ][a-zA-Zа-яА-ЯёЁіІїЇєЄ'ʼ ]*$/,
    [Inputs.LEGAL_DETAILS]: /^[a-zA-Zа-яА-ЯёЁіІїЇєЄ][a-zA-Zа-яА-ЯёЁіІїЇєЄ'ʼ\d ]*$/,
    [Inputs.MIDDLE_NAME] : /^(?!.*\.\..*)(?!.*:.*)(?!.*<.*)(?!.*;.*)[а-я. !@#$%&'*+-/=?^_'({|})~.]+$/iu,
    [Inputs.MAIL] : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    [Inputs.PHONE]: /^[\+]?3?[\s]?8?[\s]?\(?0\d{2}?\)?[\s]?\d{3}[\s|-]?\d{2}[\s|-]?\d{2}$/,
    [Inputs.BORN_DATE]: /^(?!.*\.\..*)(?!.*:.*)(?!.*<.*)(?!.*;.*)[a-z. !@#$%&'*+-/=?^_'({|})~.]+$/iu,
    [Inputs.START_DATE]: /^(?!.*\.\..*)(?!.*:.*)(?!.*<.*)(?!.*;.*)[a-z. !@#$%&'*+-/=?^_'({|})~.]+$/iu,
    [Inputs.END_DATE]: /^(?!.*\.\..*)(?!.*:.*)(?!.*<.*)(?!.*;.*)[a-z. !@#$%&'*+-/=?^_'({|})~.]+$/iu,
    [Inputs.YEAR]: /^(?!.*\.\..*)(?!.*:.*)(?!.*<.*)(?!.*;.*)[a-z. !@#$%&'*+-/=?^_'({|})~.]+$/iu,
    [Inputs.DOCUMENT_SERIES]: /^[a-z]+$/i,
    [Inputs.DOCUMENT_NUMBER]: /^-?\d+[.]\d{1,6}$/,
    [Inputs.NUMBER]: /^\d+$/,
    [Inputs.POSITIVENUMBER]: /^\d+$/,
    [Inputs.ADDRESS]: /^[a-z]+$/i,
    [Inputs.CODE]: /^\d+$/,
    [Inputs.EGRPOU]: /^\d+$/,
    [Inputs.INTERNATIONAL_PASSPORT]: /^[A-Z]{2}[0-9]{6}$/,
    [Inputs.LATIN_AND_DIGITS]: /^[a-zA-Z0-9]+$/,
};