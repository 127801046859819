import Vocabulary from "../../Vocabulary";

export function isEmpty(obj) {
    return Object.getOwnPropertyNames(obj).length === 0;
}

export function isErrorAlreadyExists(blockId, errorList) {
    if (errorList.length === 0){
        return false;
    }

    return errorList.some(e => e.blockId === blockId);
}

export function shouldButtonBeActivated(statusOfInputs, button) {
    for (const [input, status] of statusOfInputs) {
        if (status === false && input != '') {
            return true;
        }
    }
    return false;
}

export function getButton (formId) {
    if (formId !== undefined) {
        return $(`#${formId}`).find('input[type=submit]');
    }

    return $('input[type=submit]');
}

export function showErrorMessage(element, error) {
    let errorText = Vocabulary[document.documentElement.lang]['errors'][error.code];
    $(element).closest('.checkout_form_field, .auth_input, ._form_field').removeClass('_valid');
    $(element).closest('.checkout_form_field, .auth_input, ._form_field').addClass('_not-valid');
    $(element).after(`
        <div class="_error_field">${errorText}</div>
    `);
}

export function hideErrorMessage(element) {
    $(element).closest('.checkout_form_field, .auth_input, ._form_field').removeClass('_not-valid');
    $(element).closest('div').find('._error_field').remove();
}

export function showValidIcon(element) {
    $(element).closest('.checkout_form_field, .auth_input, ._form_field').addClass('_valid');
}