import { Button } from "../../../Common/AbstractComponents/Button";
import AjaxBuilder from "../../../Common/AjaxBuilder";
import { Util } from "../../../Common/Util";
import { validateField, validateFields } from "../../../Common/Validator/Validator";
import { Loader } from "../../BusPage/Loader";
import { AlertModal } from "./AlertModal";

export class ResetPasswordModal {
    constructor() {
        this._token = null;
        this._email = null;

        this._loader = new Loader();
    }

    initModal(email, token) {
        this._email = email;
        this._token = token;
        this._showModalWithResetForm();
    }

    _showModalWithResetForm() {
        new AjaxBuilder({
            route: `${Util.getLocalization()}frontend/password/reset/`,
            data: {
                token: this._token,
                email: this._email,
            },
            _token: Util.token()
        }, 'GET').execute({
            success: this._showModalWithResetFormSuccess.bind(this),
            error: this._showModalWithResetFormError.bind(this),
        })
    }

    _showModalWithResetFormSuccess(data) {
        $('.main.page').after(data.response.reset_password_modal);
        this.$modal = $('#reset_password_modal');
        this.$resetPasswordForm = this.$modal.find('form#reset_password_form');

        this.$resetPasswordForm.on('input', 'input._input-validate', this._dataValidateHandler.bind(this));
        validateFields(this.$resetPasswordForm);

        this._showModal();
    }

    _showModalWithResetFormError(data) {
        AlertModal.showModal('Сталася помилка', data.responseJSON.message);
    }

    _dataValidateHandler(event) {
        validateField(event.target);

        if ($(event.target).data('type') === 'password') {
            const confirmationPassword = this.$resetPasswordForm.find('input[name="password_confirmation"]');

            if (confirmationPassword.val().length > 0) {
                validateField(confirmationPassword);
            }
        }
    }

    _showModal() {
        this.$modal.addClass('_active');
        $('body').addClass('no_scroll');

        this._submitBtn = new Button({
            button: $('#reset_password_btn'),
            onClick: this._resetPasswordHandler.bind(this),
        });
    }

    _resetPasswordHandler(event) {
        this._loader.show();
        new AjaxBuilder({
            route: `${Util.getLocalization()}frontend/password/reset/`,
            data: {
                email: this._email,
                token: this._token,
                password: this.$resetPasswordForm.find('input[name="password"]').val(),
                password_confirmation: this.$resetPasswordForm.find('input[name="password_confirmation"]').val(),
            },
            token: Util.token()
        }, 'POST').execute({
            success: this._resetPasswordHandlerSuccess.bind(this),
            error: this._resetPasswordHandlerError.bind(this),
        });
    }

    _resetPasswordHandlerSuccess(data) {
        this._loader.hide();
        this.$modal.removeClass('_active');
        this.$modal.remove();
        this._submitBtn.destroy();
        this.$resetPasswordForm.off();
        $('body').removeClass('no_scroll');

        AlertModal.showModal(data.notification.title, data.notification.message);
    }

    _resetPasswordHandlerError(data) {
        this._loader.hide();
        const errors = data.responseJSON.errors;
        for (let key in errors) {
            const input = this.$resetPasswordForm.find(`input[name="${key}"]`);

            $(input).closest('.auth_input').addClass('_not-valid');
            $(input).after(`
                <div class="_error_field">${errors[key][0]}</div>
            `);
        }
    }
}