import { Button } from "../../../Common/AbstractComponents/Button";
import AjaxBuilder from "../../../Common/AjaxBuilder";
import { Util } from "../../../Common/Util";
import { Loader } from "../../BusPage/Loader";

export class AccountMessage {
    constructor() {
        this._init();
    }

    _init() {
        this.$accountMessage = $('#account_message');

        if (this.$accountMessage.length === 0) return false;

        this._loader = new Loader();


        this._btn = new Button({
            button: this.$accountMessage.find('#account_message_close'),
            onClick: this._closeWelcome.bind(this),
        });

        this._emailVerified = new Button({
            button: this.$accountMessage.find('#email_verified'),
            onClick: this._sendEmail.bind(this),
        })
    }

    _closeWelcome() {
        new AjaxBuilder({
            route: `${Util.getLocalization()}profile/close-welcome/`,
            token: Util.token(),
        }, 'POST').execute({
            success: this._closeWelcomeSuccess.bind(this),
        });
    }

    _closeWelcomeSuccess(data) {
        this.$accountMessage.remove();
        this._btn.destroy();
    }

    _sendEmail() {
        this._loader.show();
        new AjaxBuilder({
            route: `${Util.getLocalization()}frontend/email/resend/`,
            data: {
                email: this.$accountMessage.find('input[name="user_email"]').val(),
                token: Util.token(),
            },
            token: Util.token(),
        }, 'POST').execute({
            success: this._sendEmailSuccess.bind(this),
            error: this._sendEmailError.bind(this),
        })
    }

    _sendEmailSuccess(data) {
        this._loader.hide();

        this.$accountMessage.find('.account_message_text').html(data.message);
    }

    _sendEmailError(data) {
        console.log(data);
        this._loader.hide();
    }

    
}