import moment from 'moment';
import { Inputs, regExpressions, inputToErrorsMapping } from './constrains';

export const functions = {
    test: (value, field) => {
        if (value.length === 0) return true;
        const test = regExpressions[field].test(value);
        if (!test) return inputToErrorsMapping[field].DEFAULT_ERROR;
        return true;
    },
    phone: (value, field) => {
        if (value.length === 0) return true;
        let cleanedPhoneNumber = value.replace(/[\s()-]/g, "");
        const test = regExpressions[field].test(cleanedPhoneNumber);
        if (!test) return inputToErrorsMapping[field].DEFAULT_ERROR;
        return true;
    },
    date: (value, field) => {
        if (value.length === 0) return true;
        const isValidDate = moment(value, moment.ISO_8601, true).isValid() || moment(value, 'DD/MM/YYYY', true).isValid();
        if (!isValidDate) return inputToErrorsMapping[field].DEFAULT_ERROR;
        return true;
    },
    start_date: (value, field) => {
        if (value.length === 0) return true;

        const isSame = moment().isSame(moment(value));
        if (isSame) return inputToErrorsMapping[field].DATE_IS_SAME_ERROR;
        
        const isBefore = moment(value).isBefore(moment());
        if (isBefore) return inputToErrorsMapping[field].DATE_IS_BEFORE_ERROR;

        return true;
    },
    end_date: (value, field) => {
        if (value.length === 0) return true;
        const isSame = moment().isSame(moment(value));
        if (isSame) return inputToErrorsMapping[field].DATE_IS_SAME_ERROR;
        
        const isBefore = moment(value).isBefore(moment());
        if (isBefore) return inputToErrorsMapping[field].DATE_IS_BEFORE_ERROR;

        return true;
    },
    age: (minAge, maxAge) => (value, field) => {
        const date = moment(value, ['YYYY-MM-DD', 'DD/MM/YYYY'], true);

        if (!date.isValid()) return inputToErrorsMapping[field].DEFAULT_ERROR; 

        const year = moment().diff(date, 'years', false);

        if (year < minAge) return inputToErrorsMapping[field].AGE_MIN_ERROR;
        if (year > maxAge) return inputToErrorsMapping[field].AGE_MAX_ERROR;
        return true;
    },
    documentSeries: (value, field) => {
        if (value.length === 0) return true;
        const test = value.length === 2; 
        if (!test) return inputToErrorsMapping[field].DEFAULT_ERROR;
        return true;
    },
    documentNumber: (value, field) => {
        if (value.length === 0) return true;

        const test = value.length === 6; 
        if (!test) return inputToErrorsMapping[field].DEFAULT_ERROR;
        return true;
    },
    comparePasswords: (value, field, compareValue) => {
        if (value.length === 0) return true;
        if (value !== compareValue) return inputToErrorsMapping[field].COMPARE_ERROR;

        return true;
    },
    length: ({min, max}) => (value, field) => {
        if (value.length === 0) return true;
        if (value.length < min) return inputToErrorsMapping[field].MIN_ERROR;
        return true;
    },
    emptiness: (value, field) => {
        if (value.length === 0) return inputToErrorsMapping[field].EMPTY_FIELD_ERROR;
        return true;
    },
    numberInRange: (min, max) => (value, field) => {
        if (value === '-' || value === '-0') return false;
        if (Number(value) >= min && Number(value) <= max) return true;
        return inputToErrorsMapping[field].DEFAULT_ERROR;
    },
    characterRange: (min, max) => (value, field) => {
        if (value.length === 0) return true;
        if (value.length >= min && value.length <= max) return true;
        return inputToErrorsMapping[field].DEFAULT_ERROR;
    },
    vin: (value, field) => {
        if (value.length === 0) return true;
        const test = value.length === 17; 
        if (!test) return inputToErrorsMapping[field].DEFAULT_ERROR;
        return true;
    },
    code: (maxCodeLength) => (value, field) => {
        if (value.length === 0) return true;
        const valueLength = value.length === maxCodeLength; 
        const test = regExpressions[field].test(value);
        if (!test || !valueLength) return inputToErrorsMapping[field].DEFAULT_ERROR;
        return true;
    }, 
    reg: (value, field) => {
        if (value.length === 0) return true;
        const test = value.length <= 8;
        if (!test) return inputToErrorsMapping[field].DEFAULT_ERROR;
        return true;
    },
    year: (value, field) => {
        if (value.length === 0) return true;

        const test = value >= 1980 && value <= 2023;
        if (!test) return inputToErrorsMapping[field].DEFAULT_ERROR;

        return true;
    },
    number: (value, field) => {
        if (value.length === 0) return true;

        const test = regExpressions.number.test(value);
        if (!test) return inputToErrorsMapping[field].DEFAULT_ERROR;
        return true;
    },
    checked: (value, field) => {
        if (!value) return inputToErrorsMapping[field].DEFAULT_ERROR;

        return true;
    },
    expirationDate: (expiration_date) => (value, field) => {
        if (value.length === 0) return true;
        const date = moment(value, ['YYYY-MM-DD', 'DD/MM/YYYY'], true);

        const isAfter = moment(date).isAfter(moment(expiration_date));
        if (!isAfter) return inputToErrorsMapping[field].DEFAULT_ERROR;
        return true;
    },
};

export const validationRequires = {
    [Inputs.NAME_EN]: [functions.test, functions.emptiness],
    [Inputs.FULLNAME_EN]: [functions.test, functions.emptiness],
    [Inputs.SURNAME_EN]: [functions.test, functions.emptiness],
    [Inputs.NAME]: [functions.test, functions.emptiness],
    [Inputs.SURNAME]: [functions.test, functions.emptiness],
    [Inputs.NAME_INTERNATIONALE]: [functions.test, functions.emptiness],
    [Inputs.LEGAL_DETAILS]: [functions.test, functions.emptiness],
    [Inputs.MIDDLE_NAME]: [functions.test, functions.emptiness],
    [Inputs.PHONE]: [functions.phone, functions.emptiness],
    [Inputs.MAIL]: [functions.test, functions.emptiness],
    [Inputs.BORN_DATE]: [functions.date, functions.emptiness, functions.age(18, 100)],
    [Inputs.BORN_DATE_CHILDREN]: [functions.date, functions.emptiness, functions.age(0, 14)],
    [Inputs.START_DATE]: [functions.date, functions.start_date, functions.emptiness],
    [Inputs.END_DATE]: [functions.date, functions.end_date, functions.emptiness],
    [Inputs.YEAR]: [functions.year, functions.emptiness],
    [Inputs.DOCUMENT_SERIES]: [functions.documentSeries, functions.emptiness],
    [Inputs.DOCUMENT_NUMBER]: [functions.documentNumber, functions.emptiness],
    [Inputs.NUMBER]: [functions.numberInRange(-99999999999.99, 99999999999.99)],
    [Inputs.POSITIVENUMBER]: [functions.numberInRange(1, 999)],
    [Inputs.VIN]: [functions.emptiness, functions.vin],
    [Inputs.ADDRESS]: [functions.emptiness],
    [Inputs.CODE]: [functions.emptiness, functions.code(10)],
    [Inputs.EGRPOU]: [functions.emptiness, functions.code(8)],
    [Inputs.REG_NUM]: [functions.reg, functions.emptiness],
    [Inputs.STREET]: [functions.emptiness, functions.characterRange(1, 30)],
    [Inputs.HOUSE]: [functions.emptiness, functions.characterRange(1, 5)],
    [Inputs.APARTMENT]: [functions.emptiness, functions.number, functions.characterRange(1, 5)],
    [Inputs.CHECKBOX]: [functions.checked],
    [Inputs.PASSWORD]: [functions.characterRange(8, 15), functions.emptiness],
    [Inputs.PASSWORD_CONFIRMATION]: [functions.comparePasswords, functions.emptiness],
    [Inputs.AVIA_BORN_DATE_ADDULTS]: [functions.date, functions.emptiness, functions.age(12, 120)],
    [Inputs.AVIA_BORN_DATE_CHILDREN]: [functions.date, functions.emptiness, functions.age(2, 11)],
    [Inputs.AVIA_BORN_DATE_BABY]: [functions.date, functions.emptiness, functions.age(0, 2)],
    [Inputs.EXPIRATION_DATE]: [functions.expirationDate(moment()), functions.emptiness],
    [Inputs.INTERNATIONAL_PASSPORT]: [functions.emptiness, functions.test],
    [Inputs.LATIN_AND_DIGITS]: [functions.emptiness, functions.test],
};