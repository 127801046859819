$(document).ready(() => {
    function truncateText(selector, maxLength) {
        $(selector).each(function () {
            const text = $(this).text().trim();
            console.log(text);
            if (text.length > maxLength) {
                $(this).text(text.slice(0, maxLength) + '...');
            }
        });
    }

    truncateText('._elipsis', 45);
});