import { Button } from "../../../Common/AbstractComponents/Button";
import AjaxBuilder from "../../../Common/AjaxBuilder";
import { Util } from "../../../Common/Util";
import { Loader } from "../../BusPage/Loader";

export class PostRegistrationModal {
    constructor() {
        this.$modal = null;

        this._loader = new Loader();
    }

    setModalHtml(modalHtmlString) {
        $('.main.page').after(modalHtmlString);
    }

    init() {
        this.$modal = $('#register_success');

        this._btn = new Button({
            button: this.$modal.find('#register_success_btn'),
            onClick: this._resendVerificationEmail.bind(this),
        })

        this._showModal();
    }

    _showModal() {
        this.$modal.addClass('_active');
    }

    _resendVerificationEmail() {
        new AjaxBuilder({
            route: `${Util.getLocalization()}frontend/email/resend/`,
            data: {},
            token: Util.token(),
        }, 'POST').execute({
            success: this._resendVerificationEmailSuccess.bind(this),
            error: this._resendVerificationEmailError.bind(this),
        })
    }

    _resendVerificationEmailSuccess(data) {
        this.$modal.find('.acccount_success_popup_notification').html(data.message).addClass('_active');

        setTimeout(() => {
            this.$modal.find('.acccount_success_popup_notification').removeClass('_active');
        }, 5000);
    }

    _resendVerificationEmailError() {
        console.log(error);
    }
}