import { Util } from "../../Common/Util";
import { AccountMessage } from "./Components/AccountMessage";
import { AuthButton } from "./Components/AuthButton";
import { AuthModal } from "./Components/AuthModal";
import { Login } from "./Components/Login";
import { PostRegistrationModal } from "./Components/PostRegistrationModal";
import { Registration } from "./Components/Registration";
import { ResetModal } from "./Components/ResetModal";
import { ResetPasswordModal } from "./Components/ResetPasswordModal";

$(document).ready(function () {


    $(document).on('click', '.account_popup_close, .account_overlay', function () {
        $('.account_popup, .account_overlay').removeClass('_active');
        $('body').removeClass('no_scroll');
    });

    $(document).on('click', '._toogle_pass', function () {
        if ($(this).hasClass('_active')) {
            $(this).removeClass('_active').closest('.auth_input').find('input').attr('type', 'password');
        } else {
            $(this).addClass('_active').closest('.auth_input').find('input').attr('type', 'text');
        }
    });

    $(document).on('click', '.acccount_success_popup_close', function () {
        $('.acccount_success_popup').removeClass('_active');
        $('body').removeClass('no_scroll');
    });

    $(document).on('click', '.header_account_btn', function () {
        $('.header_account_popup').addClass('_active');
    });

    $(document).on('click', '.header_account_popup_close', function () {
        $('.header_account_popup').removeClass('_active');
    });


    $(document).on('click', '.filter_status_title', function () {
        if ($(this).hasClass('_active')) {
            $(this).removeClass('_active');

            $(this).closest('.filter_status_wrap').find('.filter_status_list').removeClass('_active');
        } else {
            $(this).addClass('_active');
            $(this).closest('.filter_status_wrap').find('.filter_status_list').addClass('_active');
        }
    });
    $(document).on('click', '.filter_price_title', function () {
        if ($(this).hasClass('_active')) {
            $(this).removeClass('_active');
            $('.filter_price_list').removeClass('_active');
        } else {
            $(this).addClass('_active');
            $('.filter_price_list').addClass('_active');
        }
    });

    class Auth {
        constructor() {
            this._init();
        }

        _init() {
            this._accountMessage = new AccountMessage(); //Окошко с приветствием

            this._loginForm = new Login({
                destroyAuthComponents: this._destroyAuthComponents.bind(this)
            });

            this._postRegistrationModal = new PostRegistrationModal();
            this._registrationForm = new Registration({
                postRegistrationModal: this._postRegistrationModal,
                destroyAuthComponents: this._destroyAuthComponents.bind(this)
            });
            this._authModal = new AuthModal({ //Главный попап для авторизации
                loginForm: this._loginForm,
                registrationForm: this._registrationForm,
                showResetModal: this._showResetModal.bind(this),
            });

            this._authButton = new AuthButton({ //Кнопка вызова окна авторизации
                authModal: this._authModal
            });

            this._resetModal = new ResetModal(); // Окно ввода почты для сброса пароля

            this._resetPasswordModal = new ResetPasswordModal(); //Окно для сброса пароля, откроется только если отработает фунцкия _checkTokenAndOpenModal


            this._isNewlyRegisteredUser();
            this._checkTokenAndOpenModal();
        }

        _showResetModal() {
            this._authModal.hideModal();
            this._resetModal.showModal();
        }

        _checkTokenAndOpenModal() {
            const urlParams = new URLSearchParams(window.location.search);
            const resetToken = urlParams.get('token');
            const email = urlParams.get('email');

            if (resetToken) {
                // Открываем модальное окно с формой сброса пароля
                this._resetPasswordModal.initModal(email, resetToken);
            }
        }

        _isNewlyRegisteredUser() { //Если пользователь был зареган через гугл или фейбук, то потом показать окно с успешной регистрацией
            const registered = Util.getIsUserRegistered();

            if (!registered) return false;

            this._postRegistrationModal.init();
        }

        //После авторизации удалит все эти методы и комппоненты
        _destroyAuthComponents() {
            this._authModal.hideModal();
            this._loginForm.destroy();
            this._registrationForm.destroy();
            this._authModal.destroy();
            this._authButton.destroy();
            this._resetModal.destroy();
        }
    }

    new Auth();
});

$(document).mouseup(function (e) {

    let header_account_popup = $('.header_account_popup._active');
    if (!header_account_popup.is(e.target) && header_account_popup.has(e.target).length === 0) {
        $('.header_account_popup').removeClass('_active');
    }

    let filter_status_title = $('.filter_status_title._active'),
        filter_status_list = $('.filter_status_list._active');
    if (!filter_status_title.is(e.target) && filter_status_title.has(e.target).length === 0 && !filter_status_list.is(e.target) && filter_status_list.has(e.target).length === 0) {
        $('.filter_status_title, .filter_status_list').removeClass('_active');
    }

    let filter_price_title = $('.filter_price_title._active'),
        filter_price_list = $('.filter_price_list._active');
    if (!filter_price_title.is(e.target) && filter_price_title.has(e.target).length === 0 && !filter_price_list.is(e.target) && filter_price_list.has(e.target).length === 0) {
        $('.filter_price_title, .filter_price_list').removeClass('_active');
    }

    let filter_item_title = $('.filter_item_title._active');
    let filter_item_list = $('.filter_item_list._active');
    if (!filter_item_title.is(e.target) && filter_item_title.has(e.target).length === 0 && !filter_item_list.is(e.target) && filter_item_list.has(e.target).length === 0) {
        $('.filter_item_title, .filter_item_list').removeClass('_active');
    }

});