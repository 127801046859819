import { Button } from "../../../Common/AbstractComponents/Button";
import AjaxBuilder from "../../../Common/AjaxBuilder";
import { Util } from "../../../Common/Util";
import { validateField, validateFields } from "../../../Common/Validator/Validator";
import { Loader } from "../../BusPage/Loader";
import eventBus from '../../../Common/EventBus';

export class Registration {
    constructor({ postRegistrationModal, destroyAuthComponents }) {
        this._postRegistrationModal = postRegistrationModal;
        this._destroyAuthComponents = destroyAuthComponents;

        this._init();

        this._loader = new Loader();
    }

    _init() {
        this.$registrationForm = $('.account_popup_tab[data-tab="register"]');

        if (this.$registrationForm.length === 0) return false;

        this.$registrationForm.find("[type='tel']").attr('placeholder', '+380');
        this.$registrationForm.find("[type='tel']").val('380').mask('+38(099) 999-99-99');

        this._submitBtn = new Button({
            button: $('#register_customer'),
            onClick: this._submitForm.bind(this),
        });

        this.$registrationForm.on('input', 'input._input-validate', this._dataValidateHandler.bind(this));
        this.$registrationForm.find('._checkbox').change(this._dataValidateHandler.bind(this));
        this.$registrationForm.on('keyup', '[type="tel"]', this._dataValidateHandler.bind(this));
        validateFields(this.$registrationForm.find('#reg_form'));
    }

    showForm() {
        this.$registrationForm.addClass('_active');
    }

    hideForm() {
        this.$registrationForm.removeClass('_active');
    }

    _submitForm() {
        this._loader.show();
        const data = {
            first_name: this.$registrationForm.find('input[name="name"]').val(),
            last_name: this.$registrationForm.find('input[name="lastname"]').val(),
            email: this.$registrationForm.find('input[name="email"]').val(),
            phone: this.$registrationForm.find('input[name="phone"]').val().replace(/[\s()-]/g, ""),
            password: this.$registrationForm.find('input[name="password"]').val(),
            password_confirmation: this.$registrationForm.find('input[name="password_confirmation"]').val(),
        };

        new AjaxBuilder({
            route: `${Util.getLocalization()}frontend/register/`,
            data,
            token: Util.token(),
        }, 'POST').execute({
            success: this._submitFormSuccess.bind(this),
            error: this._submitFormError.bind(this),
        });
    }

    _submitFormSuccess(data) {
        eventBus.publish('checkout-auth', data.response.user);

        this._destroyAuthComponents();
        Util.setToken(data.response.new_token);

        $('.header__content .header__leng').after(data.response.account_btn);
        $('body').removeClass('no_scroll');

        //Показать окно после регистрации
        this._postRegistrationModal.setModalHtml(data.response.register_success);
        this._postRegistrationModal.init();

        this._loader.hide();
    }

    _submitFormError(data) {
        const errors = data.responseJSON.errors;
        for (let key in errors) {
            const input = this.$registrationForm.find(`input[name="${key}"]`);

            $(input).closest('.auth_input').addClass('_not-valid');
            $(input).after(`
                <div class="_error_field">${errors[key][0]}</div>
            `);
        }
        this._loader.hide();
    }

    _dataValidateHandler(event) {
        const element = event.target;

        validateField(element);

        if ($(element).data('type') === 'password') {
            const confirmationPassword = this.$registrationForm.find('input[name="password_confirmation"]');

            if (confirmationPassword.val().length > 0) {
                validateField(confirmationPassword);
            }
        }
    }

    destroy() {
        this._submitBtn.destroy();
        this.$registrationForm.off();

        return true;
    }
}